<script>
import axios from 'axios';

import ProfileRowItem from "../../profiles/ProfileRowItem.vue";

export default {
  data() {
    return {
      data: {
      }
    };
  },
  props: {
  },
  components: {
    ProfileRowItem
  },
  mounted() {
  },
  methods: {
    loadingData() {
      axios.get('/api/account/favourites/profiles', { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
  }
};
</script>

<template>
  <div>
    <div class="page-title-box d-sm-flex align-items-center justify-content-between mb-1">
      <h4 class="mb-sm-0">Favourite profiles</h4>
    </div>
    <div class="team-list list-view-filter row" v-if="data.profiles && data.profiles.length > 0">
      <div v-for="p in data.profiles" v-bind:key="p">
        <ProfileRowItem v-bind:data="p"></ProfileRowItem>
      </div>
    </div>
  </div>
</template>